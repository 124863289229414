<template>
  <!-- 外层div不能去，影响显示高度计算 -->
  <div class="tablePagDiv">
    <!-- 表格和分页 -->
    <el-table
      ref="comTable"
      :data="tableData"
      row-key="id"
      :header-cell-style="{ background: '#F5F5F5', color: '#7D7D7D' }"
      class="tableAll"
      stripe
      @row-click="rowClick"
      @cell-click="cellClick">
      <!-- 复选框--默认不显示 :showSelectColumn="true/false" -->
      <el-table-column v-if="showSelectColumn" fixed type="selection" width="55"></el-table-column>
      <!-- 索引--默认不显示 -->
      <el-table-column v-if="showIndexColumn" type="index" label="序号" width="80"></el-table-column>
      <!-- align：对齐方式，未设置表头的，因此在这里表头跟内容一起，left/center/right -->
      <!-- fixed：为true表示左侧固定. 或可接受传入字符串，left 或 right -->
      <!-- msgShow： -->
      <!-- formatter：格式化数据方法 -->
      <el-table-column v-for="(item, index) in tablecol" :key="index" :prop="item.prop" :label="item.label" :width="item.width" 
        :align="item.align"
        :fixed="item.fixed"
        :msgShow="item.msgShow"
        :formatter="item.formatter"
        show-overflow-tooltip   
      >
        <!-- multiline：多行滚动条显示 -->
        <template #default="scope" v-if="item.multiline">
          <div class="templateClass align">
            <el-scrollbar style="height: 100%;">
              {{scope.row[item.prop]}}
            </el-scrollbar>
          </div>
        </template>

        <!-- scopeShow：自定义插入显示 -->
        <template #default="scope" v-if="item.scopeShow">
          <!-- 操作按钮 -->
          <!-- <span class="textButton" @click="scopeClick(scope.row.id)">{{item.label}}</span> -->
          <!-- <el-button v-if="item.scopeShow" @click="scopeClick(scope.row.id)" type="primary" size="small">{{item.label}}</el-button> -->
          <!-- 无操作 -->
          <span class="textButton ml10" v-if="scope.row.role_id == 0">--</span>
          <!-- 多个操作按钮 -->
          <span class="textButton ml10" v-else v-for="(textBtn, index) in textBtnList" :key="index" @click="scopeClick(scope.row, textBtn)">{{textBtn.label}}</span>
        </template>

      </el-table-column>
    </el-table>
    <el-pagination
      background
      class="pagination"
      v-model:current-page="currpage"
      layout="prev,pager,next,sizes,total,jumper"
      :page-sizes="pageSizes"
      v-model:page-size="pagesize"
      :total="tableCount"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  name: 'tablePagination',
  props: {
    showSelectColumn: { type: Boolean, default: false, required: false }, // 是否显示复选框
    showIndexColumn: { type: Boolean, default: false, required: false }, // 是否显示索引
    tablecol: { type: Array, default: [], required: false }, // 表头数据
    textBtnList: { type: Array, default: [], required: false }, // 文字按钮列表数据
    url: { type: String, default: "", required: false }, // 请求url
    params: { type: Object, default: {}, required: false }, // 参数
    pagesize: { type: Number, default: 5, required: false }, // 单页数据量
    pageSizes: { type: Array, default: [5, 10, 15, 20, 30, 40], required: false },
  },
  data() {
    return {
      tableData:[],  // 表格数据
      tableCount:0,  // 分页数据总数
      currpage:1, // 当前页码
    };
  },
  methods: {
    //获取数据
    getTable() {
      let url = this.url
      this.$axios.get(url, {
        params: {
          page: this.currpage,
          limit: this.pagesize,
          ...this.params
        },
      })
      .then((res) => {
        if (res.data.code === 200) {
          // debugger
          this.tableCount = res.data.count
          if(res.data.data){
            this.tableData = res.data.data
          }else{
            this.tableData = res.data.result
          }
        } else {
          this.$ElMessage.error(res.data.message);
        }
      });
    },
    // 页码
    handleCurrentChange (cpage) {
      // this.currpage = cpage
      this.getTable();
    },
    // 每页条数
    handleSizeChange (psize) {
      this.currpage = 1
      this.getTable();
    },
    //选择数据发生变化时
    getRowSelection(){
      return this.$refs.comTable.getSelectionRows()
    },
    // 插入按钮的方法
    scopeClick(row, textBtn){
      this.$emit('scopeClick', row, textBtn)
    },
    // 删除后更新数据
    refreshDelete(){
      let deleteNum = this.$refs.comTable.getSelectionRows().length
      let totalPage = Math.ceil((this.tableCount - deleteNum)/this.pagesize)
      this.currpage = this.currpage>totalPage?totalPage:this.currpage
      this.currpage = this.currpage<1?1:this.currpage
      this.getTable()
    },
    // 清空表格
    clearSelection(){
      this.$refs.comTable.clearSelection()
    },
    // 行点击事件，触发父页面事件
    rowClick(row,column){
      this.$emit('rowClick', row)
    },
    // 单元格点击事件，多行显示的单元格点击出弹框
    cellClick(row,column){
      this.tablecol.forEach((item) => {
        if(item.label === column.label){
          if(item.msgShow && item.msgShow === true){
            this.$alert(row[item.prop], column.label, {
              confirmButtonText: '确定',
              draggable: true,
              customClass: 'msgbox'
            }).catch(()=>{})
          } 
        }
      })
    },

  },
  mounted(){
    this.getTable()
  }
};
</script>

<style>
.tablePagDiv{
  height: 100%;
  position: relative;
}
.tableAll{ height: calc(100% - 95px);}
/* .tableAll.is-scrolling-none th.el-table-fixed-column--left, .tableAll.is-scrolling-none th.el-table-fixed-column--right {background: #F5F5F5; text-align: left;} */
/* .tableAll .el-table__inner-wrapper{height: 100% !important;} */
.pagination{
  /* margin: 20px 0;
  position: absolute;
  right: 0; */
  position: relative;
  float: right;
  margin: 20px 0;
  z-index:999;
}

.templateClass{
  width:100%;
  height:70px;
  overflow:hidden;
}
.align{
  text-align:justify;
  text-justify:inter-ideograph;
  word-break: break-all;
  word-wrap: break-word;
}

/* 消息弹出框样式 */
.msgbox {
  width: 100%;
  height: auto;
}
@media all and (min-width: 768px) {
  .msgbox {width: 600px; max-height: 300px;}
  .msgbox .el-message-box__content{max-height: 200px;overflow-y: auto;}
}
</style>