<template>
    <!-- flv 视频插件播放 autoplay muted  -->
    <div class="videoGroup">
        <!-- <video :id="flvId" muted playsinline webkit-playsinline class="video-js vjs-default-skin flvVideo" :autoplay="false"></video> -->
        <video :id="flvId" muted playsinline webkit-playsinline class="video-js vjs-default-skin flvVideo" autoplay></video>
    </div>
</template>

<script>
import flvjs from 'flv.js'
export default {
    name: 'flvjsPlayer',
    // components:{
    //     VideoTitle
    // },
    props:{
        flvInfo:{
            type:Object,
            default:()=>{
                return {}
            }
        },
        url:{type: String, default: ''},
        flvId:{type: String, default: ''},
    },
    data(){
        return {
            flvPlayer: null,
            changeLampPost: false,
        }
    },
    created(){},
    mounted(){
        // this.init();
        this.$nextTick(() => {
            this.init();
        })
    },
    methods:{
        init(){
            let _this = this;
            if(_this.flvPlayer){
                _this.flvPlayer.pause();
                _this.flvPlayer.destroy();
                _this.flvPlayer = null;
            }else{
                if (flvjs.isSupported()) {
                    var videoEE = document.getElementById(_this.flvId);
                    var flvPlayer = flvjs.createPlayer(
                        {
                            type: 'flv',
                            isLive: true,
                            url: _this.url
                        },
                        {
                            enableWorker: false, //不启用分离线程
                            enableStashBuffer: false, //关闭IO隐藏缓冲区
                            reuseRedirectedURL: true, //重用301/302重定向url，用于随后的请求，如查找、重新连接等。
                            autoCleanupSourceBuffer: true //自动清除缓存
                        }
                    );
                    flvPlayer.attachMediaElement(videoEE);
                    flvPlayer.load();
                    // flvPlayer.play();

                    _this.flvPlayer = flvPlayer

                    videoEE.addEventListener('play', () => {
                        var secondsToPlay = 3;
                        setTimeout(() => {
                            flvPlayer.pause(); // 暂停播放
                            flvPlayer.unload(); // 停止加载流，这将关闭与服务器的连接
                            // flvPlayer.detachMediaElement(); // 断开播放器与video元素的关联
                            // flvPlayer.destroy(); // 销毁播放器实例
                        }, secondsToPlay * 1000);
                    });

                    // debugger
                    // _this.flvPlayer.on(flvjs.Events.STATISTICS_INFO, (res) => {
                    //     if (_this.changeLampPost) {
                    //         _this.flvPlayer.pause();
                    //         _this.flvPlayer.unload();
                    //         _this.flvPlayer.detachMediaElement();
                    //         _this.flvPlayer.destroy();
                    //         _this.flvPlayer = null;
                    //     }
                    // })
                }
            }
        },

        destroyVideo(){
            this.flvPlayer.destroy();
            this.flvPlayer= null;
        },
    },
    beforeDestroy() {
        if (this.flvPlayer) {
            this.flvPlayer.destroy();
            this.flvPlayer = null;
        }
    },
    destroyed(){
        let _this = this
        setTimeout(() => {
            _this.flvPlayer.pause()
            _this.flvPlayer.destroy()
            _this.changeLampPost = true; // 这里视频做了优化处理 
        },500)
    },
    watch:{
        changeLampPost: {
            handler(newVal){
                if (newVal) {
                    this.$nextTick(() => {
                        this.destroyVideo();
                    })
                }
            },
            immediate: true
        }
    }
}
</script>

<style lang="less" scoped>
.videoGroup{
    width:100%;
    height:100%;
    position:relative;
    overflow: hidden;
}

.flv_box{
    width: 100%;
    height: 100vh;
}
.flvVideo{  //视频撑满
    background-color: #000;
    width:100%;
    height:100%;
    object-fit: fill;
}
</style>
