/**
 * 各种画echarts图表的方法都封装在这里
 */

import { Size } from '@supermap/iclient-ol'
import echarts from 'echarts'
import { color } from 'echarts/lib/export'
import { markRaw } from 'vue'
const install = function (Vue) {
  Object.defineProperties(Vue.config.globalProperties, {
    $chart: {
      get() {
        return {
          // 只有一个颜色的普通柱状图
          barOneCol: function (id, data) {
            this.chart = echarts.init(document.getElementById(id))
            this.chart.clear()
            const optionData = {
              title: {
                text: data.title,
                textStyle: {
                  color: '#4A4A4A',
                  fontWeight: 500,
                },
              },
              tooltip: {
                confine: true,
                textStyle: {
                  align: 'left'
                },
                extraCssText: "z-index:99"
              },
              grid: {
                top: '20%',
                right: '2%',
                left: '2%',
                bottom: '3%',
                containLabel: true
              },
              xAxis: {
                data: data.labelArr,
                axisLabel: {
                  interval: 0, //坐标刻度之间的显示间隔，默认就可以了（默认是不重叠）
                },
                axisTick:{
                  show:false // 不显示坐标轴刻度线
                },
                axisLine: {
                  show: false, // 不显示坐标轴线
                },
              },
              yAxis: {
                type: 'value',
                axisLabel: {
                  margin: 20,
                },
                axisTick:{
                  show:false // 不显示坐标轴刻度线
                },
                axisLine: {
                  show: false, // 不显示坐标轴线
                },
              },
              series: [{
                data: data.valueArr,
                type: 'bar',
                label: {
                  show: true, // 显示标签
                  position: 'top' // 标签位置顶部
                },
                color: data.color,
                backgroundStyle: {
                  color: 'rgba(220, 220, 220, 0.8)'
                },
                // barWidth: '50%',
                barMaxWidth: '20'
              }]
            }
            this.chart.setOption(optionData)
          },
          // 柱状图
          bar: function (id, data) {
            this.chart = echarts.init(document.getElementById(id))
            this.chart.clear()
            const optionData = {
              title: {
                text: data.title,
                textStyle: {
                  color: '#4A4A4A',
                  fontWeight: 500,
                },
              },
              tooltip: {
                confine: true,
                textStyle: {
                  align: 'left'
                },
                extraCssText: "z-index:99",
                formatter: '{b} : {c}' + data.unit
              },
              grid: {
                top: '20%',
                right: '2%',
                left: '2%',
                bottom: '3%',
                containLabel: true
              },
              xAxis: {
                data: data.labelArr,
                position: 'left',
                axisLabel: {
                  interval: 0, //坐标刻度之间的显示间隔，默认就可以了（默认是不重叠）
                },
                axisTick:{
                  show:false // 不显示坐标轴刻度线
                },
                axisLine: {
                  show: false, // 不显示坐标轴线
                },
              },
              yAxis: {
                type: 'value',
                axisLabel: {
                  margin: 20,
                },
                axisTick:{
                  show:false // 不显示坐标轴刻度线
                },
                axisLine: {
                  show: false, // 不显示坐标轴线
                },
              },
              series: [{
                data: data.valueArr,
                type: 'bar',
                label: {
                  show: true, // 显示标签
                  position: 'top' // 标签位置顶部
                },
                itemStyle: {
                  normal: {
                    //这里是颜色
                    color: function (params) {
                      if(data.color[params.dataIndex]){
                        return data.color[params.dataIndex]
                      }else{
                        // 生成一个随机颜色
                        return 'rgb(' +
                        Math.round(Math.random() * 255) + ',' +
                        Math.round(Math.random() * 255) + ',' +
                        Math.round(Math.random() * 255) + ')';
                      }
                    }
                  }
                },
                backgroundStyle: {
                  color: 'rgba(220, 220, 220, 0.8)'
                },
                barMaxWidth: '20'
              }]
            }
            this.chart.setOption(optionData)
          },
          // 横向柱状图
          barTrans: function (id, data) {
            this.chart = echarts.init(document.getElementById(id))
            this.chart.clear()
            const optionData = {
              title: {
                text: data.title,
                textStyle: {
                  color: '#4A4A4A',
                  fontWeight: 500,
                },
              },
              tooltip: {
                confine: true,
                textStyle: {
                  align: 'left'
                },
                extraCssText: "z-index:99",
                // formatter: '{b} : {c}' + data.unit
              },
              grid: {
                top: '20%',
                right: '5%',
                left: '2%',
                bottom: '3%',
                containLabel: true
              },
              xAxis: {
                type: 'value',
                axisTick:{
                  show:false // 不显示坐标轴刻度线
                },
                axisLine: {
                  show: false, // 不显示坐标轴线
                },
              },
              yAxis: {
                data: data.labelArr,
                axisLabel: {
                  margin: 20,
                  interval: 0, //坐标刻度之间的显示间隔，默认就可以了（默认是不重叠）
                },
                axisTick:{
                  show:false // 不显示坐标轴刻度线
                },
                axisLine: {
                  show: false, // 不显示坐标轴线
                },
              },
              series: [{
                data: data.valueArr,
                type: 'bar',
                label: {
                  show: true, // 显示标签
                  position: 'right' // 标签位置顶部
                },
                itemStyle: {
                  normal: {
                    //这里是颜色
                    color: function (params) {
                      return data.color[params.dataIndex % data.color.length]
                    }
                  }
                },
                backgroundStyle: {
                  color: 'rgba(220, 220, 220, 0.8)'
                },
                barMaxWidth: '20'
              }]
            }
            this.chart.setOption(optionData)
          },
          // 饼图
          zzyztPie: function (id, data) {
            this.chart = echarts.init(document.getElementById(id))
            this.chart.clear()
            const optionData = {
              title: {
                text: data.title,
                textStyle: {
                  color: '#4A4A4A',
                  fontWeight: 500,
                },
              },
              tooltip: {
                trigger: 'item',
                confine: true,
                textStyle: {
                  align: 'left'
                },
                extraCssText: "z-index:99",
                formatter: '{a} <br/>{b} : {c}' + data.unit + ' ({d}%)'
              },
              // legend: {
              //   top: '10%',
              //   left: 'center'
              // },
              series: [
                {
                  name: data.title,
                  type: 'pie',
                  // top: '10%',
                  bottom: '5%',
                  radius: ['35%', '70%'],
                  center: ['50%', '50%'],
                  labelLine: {
                    length: 20,
                    length2: 20,
                  },
                  avoidLabelOverlap: false,
                  label: {
                    formatter: function (params) {
                      return data.valueArr[params.dataIndex].name
                    },
                    minMargin: 5,
                    edgeDistance: 10,
                    lineHeight: 15,
                  },
                  emphasis: {
                    itemStyle: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                  },
                  data: data.valueArr,
                  itemStyle: {
                    normal: {
                      // 放series外面可以跟图例颜色一致
                      color: function (params) {
                        // return data.color[params.dataIndex % data.color.length]
                        if(data.color[params.dataIndex]){
                          return data.color[params.dataIndex]
                        }else{
                          // 生成一个随机颜色
                          return 'rgb(' +
                          Math.round(Math.random() * 255) + ',' +
                          Math.round(Math.random() * 255) + ',' +
                          Math.round(Math.random() * 255) + ')';
                        }
                      },
                    }
                  }
              }],
            }
            this.chart.setOption(optionData)
          },
          // 饼图
          pie: function (id, data) {
            this.chart = echarts.init(document.getElementById(id))
            this.chart.clear()
            const optionData = {
              title: {
                text: data.title,
                textStyle: {
                  color: '#4A4A4A',
                  fontWeight: 500,
                },
              },
              tooltip: {
                trigger: 'item',
                confine: true,
                textStyle: {
                  align: 'left'
                },
                extraCssText: "z-index:99",
                formatter: '{a} <br/>{b} : {c}' + data.unit + ' ({d}%)'
              },
              // legend: {
              //   top: '10%',
              //   left: 'center'
              // },
              series: [
                {
                  name: data.title,
                  type: 'pie',
                  top: '10%',
                  radius: ['45%', '70%'],
                  center: ['50%', '50%'],
                  labelLine: {
                    length: 30,
                    length2: 20,
                  },
                  avoidLabelOverlap: false,
                  label: {
                    formatter: function (params) {
                      return data.valueArr[params.dataIndex].name + ' | ' + data.valueArr[params.dataIndex].value
                    },
                    minMargin: 5,
                    edgeDistance: 10,
                    lineHeight: 15,
                  },
                  emphasis: {
                    itemStyle: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                  },
                  data: data.valueArr,
                  itemStyle: {
                    normal: {
                      // 放series外面可以跟图例颜色一致
                      color: function (params) {
                        // return data.color[params.dataIndex % data.color.length]
                        if(data.color[params.dataIndex]){
                          return data.color[params.dataIndex]
                        }else{
                          // 生成一个随机颜色
                          return 'rgb(' +
                          Math.round(Math.random() * 255) + ',' +
                          Math.round(Math.random() * 255) + ',' +
                          Math.round(Math.random() * 255) + ')';
                        }
                      },
                    }
                  }
              }],
            }
            this.chart.setOption(optionData)
          },
          // 虫情观测仪的圆环饼图
          cqgcyYHPie: function (id, data) {
            this.chart = echarts.init(document.getElementById(id))
            this.chart.clear()
            const optionData = {
              title: {
                text: data.title,
                textStyle: {
                  color: '#000',
                  fontSize: 13,
                  fontWeight: 800,
                },
                left: 'left',
              },
              tooltip: {
                trigger: 'item',
                confine: true,
                textStyle: {
                  align: 'left'
                },
                extraCssText: "z-index:99",
                formatter: '{a} <br/>{b} : {c}' + data.unit + ' ({d}%)'
              },
              legend: {
                top: 20,
                orient: 'vertical',
                left: 'left',
              },
              series: [
                {
                  name: data.title,
                  type: 'pie',
                  top: '10%',
                  bottom: '10%',
                  left: '20%',
                  radius: ['40%', '80%'],
                  center: ['50%', '50%'],
                  labelLine: {
                    length: 20,
                    length2: 20,
                  },
                  avoidLabelOverlap: false,
                  label: {
                    formatter: function (params) {
                      return data.valueArr[params.dataIndex].name + ' | ' + data.valueArr[params.dataIndex].value
                    },
                    minMargin: 5,
                    edgeDistance: 10,
                    lineHeight: 15,
                  },
                  emphasis: {
                    itemStyle: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                  },
                  data: data.valueArr,
                  itemStyle: {
                    normal: {
                      // 放series外面可以跟图例颜色一致
                      color: function (params) {
                        // return data.color[params.dataIndex % data.color.length]
                        if(data.color[params.dataIndex]){
                          return data.color[params.dataIndex]
                        }else{
                          // 生成一个随机颜色
                          return 'rgb(' +
                          Math.round(Math.random() * 255) + ',' +
                          Math.round(Math.random() * 255) + ',' +
                          Math.round(Math.random() * 255) + ')';
                        }
                      },
                    }
                  }
              }],
            }
            this.chart.setOption(optionData)
          },
          // 虫情观测仪的饼图
          cqgcyPie: function (id, data) {
            this.chart = echarts.init(document.getElementById(id))
            this.chart.clear()
            const optionData = {
              title: {
                text: data.title,
                textStyle: {
                  color: '#000',
                  fontSize: 13,
                  fontWeight: 800,
                },
                left: 'left',
              },
              tooltip: {
                trigger: 'item',
                confine: true,
                textStyle: {
                  align: 'left'
                },
                extraCssText: "z-index:99",
                formatter: '{a} <br/>{b}({d}%)'
              },
              legend: {
                top: 20,
                orient: 'vertical',
                left: 'left',
              },
              series: [
                {
                  name: data.title,
                  type: 'pie',
                  top: '10%',
                  bottom: '10%',
                  left: '20%',
                  radius: '75%',
                  center: ['50%', '50%'],
                  labelLine: {
                    length: 20,
                    length2: 20,
                  },
                  avoidLabelOverlap: false,
                  label: {
                    formatter: function (params) {
                      return data.valueArr[params.dataIndex].name
                    },
                    minMargin: 5,
                    edgeDistance: 10,
                    lineHeight: 15,
                  },
                  emphasis: {
                    itemStyle: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                  },
                  data: data.valueArr,
                  itemStyle: {
                    normal: {
                      // 放series外面可以跟图例颜色一致
                      color: function (params) {
                        // return data.color[params.dataIndex % data.color.length]
                        if(data.color[params.dataIndex]){
                          return data.color[params.dataIndex]
                        }else{
                          // 生成一个随机颜色
                          return 'rgb(' +
                          Math.round(Math.random() * 255) + ',' +
                          Math.round(Math.random() * 255) + ',' +
                          Math.round(Math.random() * 255) + ')';
                        }
                      },
                    }
                  }
              }],
            }
            this.chart.setOption(optionData)
          },
          // 折线图
          foldLine: function (id, data) {
            this.chart = echarts.init(document.getElementById(id))
            this.chart.clear()
            const optionData = {
              title: {
                text: data.title,
                textStyle: {
                  color: '#4A4A4A',
                  fontWeight: 500,
                },
              },
              tooltip: {
                trigger: 'axis'
              },
              legend: {
                right: '5%',
                width: '70%',
              },
              grid: {
                top: '20%',
                right: '2%',
                left: '2%',
                bottom: '3%',
                containLabel: true
              },
              xAxis: {
                type: 'category',
                boundaryGap: false,
                data: data.labelArr,
                axisTick:{
                  show:false // 不显示坐标轴刻度线
                },
                axisLine: {
                  show: false, // 不显示坐标轴线
                },
              },
              yAxis: {
                type: 'value',
                axisLabel: {
                  margin: 20,
                  formatter: '{value} '+ data.unit // 设置单位为㎡
                },
                axisTick:{
                  show:false // 不显示坐标轴刻度线
                },
                axisLine: {
                  show: false, // 不显示坐标轴线
                },
              },
              series: data.valueArr,
              // 放外面可以跟图例颜色一致
              color: function (params) {
                // return data.color[params.dataIndex % data.color.length]
                if(data.color[params.dataIndex]){
                  return data.color[params.dataIndex]
                }else{
                  // 生成一个随机颜色
                  return 'rgb(' +
                  Math.round(Math.random() * 255) + ',' +
                  Math.round(Math.random() * 255) + ',' +
                  Math.round(Math.random() * 255) + ')';
                }
              },
            }
            this.chart.setOption(optionData)
          },
          // 设备数据部分的折线图
          tfoldLine: function (id, data) {
            this.chart = echarts.init(document.getElementById(id))
            this.chart.clear()
            const optionData = {
              title: {
                text: data.title,
              },
              tooltip: {
                trigger: 'axis'
              },
              grid: {
                top: '20%',
                right: '2%',
                left: '2%',
                bottom: '3%',
                containLabel: true
              },
              xAxis: {
                type: 'category',
                boundaryGap: true,
                data: data.labelArr,
                scale: true,
                axisLabel: {
                  formatter: function (params) {
                    var str = "";
                    var paramsLen = params.length;
                    var len = 10;                  // 每行能显示的字的个数
                    var rowNumber = Math.ceil(paramsLen / len);
                    if (paramsLen > len) {        //大于设定的len就换行，不大于就不变化
                      for (var i = 0; i < rowNumber; i++) {
                        var temp = "";
                        var start = i * len;
                        var end = start + len;
                        if (i == rowNumber - 1) {
                          temp = params.substring(start, paramsLen);
                        } else {
                          temp = params.substring(start, end) + "\n";
                        }
                        str += temp;
                      }
                    } else {
                      str = params;
                    }
                    return str;
                  }
                },
                axisTick:{
                  show:false // 不显示坐标轴刻度线
                },
                axisLine: {
                  show: false, // 不显示坐标轴线
                },
              },
              yAxis: {
                type: 'value',
                name: data.unit,
                // axisLabel: {
                //   margin: 20,
                // },
                axisTick:{
                  show:false // 不显示坐标轴刻度线
                },
                axisLine: {
                  show: false, // 不显示坐标轴线
                },
              },
              series: data.valueArr,
              // 放外面可以跟图例颜色一致
              color: function (params) {
                // return data.color[params.dataIndex % data.color.length]
                if(data.color[params.dataIndex]){
                  return data.color[params.dataIndex]
                }else{
                  // 生成一个随机颜色
                  return 'rgb(' +
                  Math.round(Math.random() * 255) + ',' +
                  Math.round(Math.random() * 255) + ',' +
                  Math.round(Math.random() * 255) + ')';
                }
              },
            }
            this.chart.setOption(optionData)
          },
          // 积光双纵轴折线图
          twofoldLine: function (id, data) {
            this.chart = echarts.init(document.getElementById(id))
            this.chart.clear()
            const optionData = {
              title: {
                text: data.title,
              },
              tooltip: {
                trigger: 'axis'
              },
              grid: {
                top: '20%',
                right: '5%',
                left: '2%',
                bottom: '3%',
                containLabel: true
              },
              xAxis: {
                type: 'category',
                boundaryGap: true,
                data: data.labelArr,
                scale: true,
                axisLabel: {
                  formatter: function (params) {
                    var str = "";
                    var paramsLen = params.length;
                    var len = 10;                  // 每行能显示的字的个数
                    var rowNumber = Math.ceil(paramsLen / len);
                    if (paramsLen > len) {        //大于设定的len就换行，不大于就不变化
                      for (var i = 0; i < rowNumber; i++) {
                        var temp = "";
                        var start = i * len;
                        var end = start + len;
                        if (i == rowNumber - 1) {
                          temp = params.substring(start, paramsLen);
                        } else {
                          temp = params.substring(start, end) + "\n";
                        }
                        str += temp;
                      }
                    } else {
                      str = params;
                    }
                    return str;
                  }
                },
                axisTick:{
                  show:false // 不显示坐标轴刻度线
                },
                axisLine: {
                  show: false, // 不显示坐标轴线
                },
              },
              yAxis: [
                  {
                      type: 'value',
                      name: '（积光）μmol',
                      position: 'left',
                      axisTick:{
                        show:false // 不显示坐标轴刻度线
                      },
                      axisLine: {
                        show: false, // 不显示坐标轴线
                      },
                  },
                  {
                      type: 'value',
                      name: '（日照）h',
                      position: 'right',
                      right: '2%',
                      x: '-15',
                      // 双纵轴时，可以设置不同的刻度范围
                      // splitLine: {
                      //     show: false
                      // },
                      axisTick:{
                        show:false // 不显示坐标轴刻度线
                      },
                      axisLine: {
                        show: false, // 不显示坐标轴线
                      },
                  }
              ],
              series: data.valueArr,
              // 放外面可以跟图例颜色一致
              color: function (params) {
                // return data.color[params.dataIndex % data.color.length]
                if(data.color[params.dataIndex]){
                  return data.color[params.dataIndex]
                }else{
                  // 生成一个随机颜色
                  return 'rgb(' +
                  Math.round(Math.random() * 255) + ',' +
                  Math.round(Math.random() * 255) + ',' +
                  Math.round(Math.random() * 255) + ')';
                }
              },
            }
            this.chart.setOption(optionData)
          },
          //清除
          clear: function (id) {
            this.chart = echarts.init(document.getElementById(id))
            this.chart.clear()
          },
          //暂无数据
          echartsNoData: function (id) {
            let dom = document.getElementById(id);
            dom.innerHTML = "暂无数据";
            // dom.removeAttribute("_echarts_instance_");
          },
          //清除_echarts_instance_
          clearEchartsInstance: function (id) {
            let dom = document.getElementById(id);
            if (dom.getAttribute("_echarts_instance_")) {
              dom.removeAttribute("_echarts_instance_");
            }
          },
        }
      }
    }
  })
}
export default {
  install
}